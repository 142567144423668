export const notify = {
  2001: 'Sorry, the meeting room is closed', //'对不起，会议室已被关闭',
  2002: 'The administrator has enabled your video', //'管理员已开启您的视频',
  2003: 'The administrator has stopped your video', //'管理员已停止您的视频',
  2004: 'The host has started your video', //'主持人已开启您的视频',
  2005: 'The host has stopped your video', //'主持人已停止您的视频',
  2006: 'The administrator has unmuted you', //'管理员已将您解除静音',
  2007: 'The administrator has muted you', // '管理员已将您静音',
  2008: 'The host has de-muted you', //'主持人已将您解除静音',
  2009: 'The host has muted you', //'主持人已将您静音',
  2010: 'The administrator has asked you out of the meeting room', //'管理员已将您请出会议室',
  2011: 'The administrator has locked the meeting room, please wait for the administrator to unlock it', //'管理员已锁定会议室，请等待管理员解锁',
}

// 代理服务器的错误码
export const proxyCode = {
  100: 'Failed to connect to the conference server', //'连接会议服务器失败',
  4098: 'Server exception error, please try again later', //'服务器异常错误，请稍后重试', // 异常错误
  4099: 'Server exception error, please try again later', //'服务器异常错误，请稍后重试',
  4101: 'User not found', //'用户未找到',
  4102: 'Verification failed', //'验证失败',
  4103: 'User does not have access', //'用户无权访问',
  4104: 'Parameter error',  //'参数错误',
  4105: 'The system is under maintenance, the service is suspended', //'系统正在维护，暂停服务',
  4106: 'Too many visitors, service suspended', //'访问人数过多，暂停服务',
  8449: 'Wrong username or password', //'用户名或密码错误',
  8450: 'Meeting room does not exist', //'会议室不存在', // 房间未找到
  8451: 'The meeting room is full', //'会议室人数已满', // 房间已满
  8455: 'The server is full', //'服务器已满员', // 服务器已满员
  8456: 'The meeting room user already exists', //'会议室用户已存在', // 用户已存在
  8457: 'A room password is required', //'需要输入房间密码',
  8458: 'Admin room is locked, please wait for admin to unlock', //'管理员会议室已锁定, 请等待管理员解锁', // 房间已锁定
  8459: 'Meeting room expired', // '会议室已过期', // 会议室已过期
  8460: 'Meeting room closed', //'会议室已关闭', // 房间已关闭
  8461: 'The administrator denies the user access to the locked room', //'管理员拒绝用户进入锁定房间', // 主持人拒绝用户进入锁定房间的请求
  8462: 'Users are blacklisted and banned from entering locked rooms', //'用户被列入黑名单，禁止进入锁定房间',
  8466: 'If the product service has expired or the balance is insufficient, please renew the fee in time', //'产品服务已到期或余额不足，请及时续费', // 服务已到期或余额不足，请及时续费
  8467: 'The ordered product service has been deactivated', //'订购的产品服务被停用',
  8469: 'The meeting room number does not exist, please reenter it', //'该会议室号不存在，请重新输入',
  8470: 'Access is denied and only login with account and password is allowed', //'访问被拒绝，只允许以账号密码方式登录', // 访问被拒绝，只允许以用户名密码方式登录
  8473: 'Invitation code error', //'邀请码错误',
  8474: 'Expired invitation code', //'邀请码过期',
  8476: 'Meeting room not available (not open)',//'预约会议室未到时间(未开启)',
  8479: 'Token state error', //'Token状态错误',
}

export const errCode = {
  1001: 'Supports Chrome 88 and above or Microsoft Edge 88 and above', //'支持Chrome 88及以上或者Microsoft Edge 88及以上版本的浏览器',
  1002: 'Login to paas failed', //'登录paas失败',
  1003: 'Failed to get the proxy server address', //'获取代理服务器地址失败',
  1004: 'Failure to get meeting information', //'获取会议信息失败',
  1005: 'Only the administrator has the right to close the meeting room', // '管理员才有关闭会议室的权限',
  1006: 'The current product does not support, can contact sales for consultation', //'当前产品不支持，可联系销售进行咨询',
}

export const defaultResolutionList = [
  {
    key: 180,
    value: '320 x 180',
  },
  {
    key: 360,
    value: '640 x 360',
  },
  {
    key: 540,
    value: '960 x 540',
  },
  {
    key: 720,
    value: '1280 x 720',
  },
  {
    key: 1080,
    value: '1920 x 1080',
  },
]
export const RESOLUTIONMAP = {
  180: [320, 180],
  360: [640, 360],
  540: [960, 540],
  720: [1280, 720],
  1080: [1920, 1080],
}

// 布局类型
export const LAYOUT_TYPE = {
  DATA: 2, // 纯数据布局
  VIDEO: 3, // 纯视频布局
  DATA_VIDEO: 1, // 数据 + 视频布局
}

// 用户在线状态
const userOnlineStateData = [
  { key: 0, english: 'offline', chinese: 'offline' }, // 离线
  { key: 1, english: 'inMeeting', chinese: 'inMeeting' }, // 会议中
  { key: 2, english: 'online', chinese: 'online' }, // 在线
]
const USER_ONLINE_STATE = {}
userOnlineStateData.forEach(({ key, english, chinese }) => {
  USER_ONLINE_STATE[key] = { english, chinese }
  USER_ONLINE_STATE[english] = key
})
export { USER_ONLINE_STATE }
