<template>
  <a-modal
    v-model="show"
    :mask-closable="false"
    :closable="false"
    :keyboard="false"
    :width="420"
    :footer="null"
  >
    <div class="nt-container">
      <div class="nt-item">{{ message }}</div>
      <div class="nt-footer">
        <a-button
          type="primary"
          @click="closeNotice"
          class="sure-btn"
          size="small"
          >Got it</a-button
        >
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'ForceLogout',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    userName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      message: '',
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    show(value) {
      if (value) {
        let countDown = 5
        this.message = `"${this.userName}"It has been used in other devices，Automatically jump to login after ${countDown} seconds`
        this.backLoginTimer = setInterval(() => {
          countDown--
          if (countDown <= 0) {
            this.closeNotice()
          } else {
            this.message = `"${this.userName}"It has been used in other devices，Automatically jump to login after ${countDown} seconds`
          }
        }, 1000)
      }
    },
  },
  methods: {
    closeNotice() {
      this.show = false
      this.backLoginTimer && clearInterval(this.backLoginTimer)
      this.$router.replace('/login')
    },
  },
}
</script>

<style lang="less" scoped>
.nt-container {
  padding: 30px 30px 15px;
  .nt-item {
    word-break: break-all;
  }
  .nt-footer {
    margin-top: 30px;
    text-align: right;
    .sure-btn {
      width: 100px;
      height: 30px;
      background: #5aa4f7;
      border-color: #5aa4f7;
      color: #fff;
    }
  }
}
</style>
