<template>
  <a-tree
    class="departments-tree"
    :replace-fields="replaceFields"
    :tree-data="data"
    :selected-keys="[selectedKey]"
    show-icon
    @select="handleSelect"
  >
    <img
      class="organization-icon"
      src="@/assets/img/address-book/organization_icon.png"
      slot="organizationIcon"
      alt="organization icon"
    />
  </a-tree>
</template>

<script>
export default {
  name: 'DepartmentsTree',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    replaceFields: {
      type: Object,
      default: () => {
        return { children: 'children', title: 'title', key: 'key' }
      },
    },
    selectedKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {}
  },
  methods: {
    handleSelect(selectedKeys) {
      this.$emit(
        'select',
        selectedKeys[0] === undefined ? this.selectedKey : selectedKeys[0]
      )
    },
  },
}
</script>

<style lang="less" scoped>
.departments-tree {
  .organization-icon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    margin-top: -1px;
  }
  /deep/ .ant-tree-switcher-icon {
    margin-top: 2px;
    font-size: 22px !important;
  }
  /deep/ .ant-tree-node-selected {
    background-color: #bddfff !important;
  }
  /deep/ .ant-tree-node-content-wrapper:hover {
    background-color: #ebf0f5;
  }
}
</style>
