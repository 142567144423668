<template>
  <div class="address-book">
    <div class="departments-users">
      <div class="head">
        <div>Selecting Members</div>
        <div class="refresh-btn" @click="onRefreshUsers"></div>
      </div>
      <div class="main">
        <div class="title">Contacts within the enterprise</div>
        <div class="content">
          <div class="departments">
            <departments-tree
              :replace-fields="departmentsReplaceFields"
              :data="departmentsList"
              :selected-key="selectedDepartmentId"
              @select="onDepartmentSelect"
            />
          </div>
          <a-spin
            class="users-list-spin"
            :spinning="isFetchingUsersApi"
            size="large"
          >
            <div class="users" v-if="usersList.length > 0">
              <div
                class="item"
                v-for="item in usersListSortedByState"
                :key="item.userId"
              >
                <a-checkbox
                  :checked="isSelected(item)"
                  :disabled="item.onlineState !== USER_ONLINE_STATE.online"
                  @change="onUserSelectChange($event, item)"
                  >{{ item.displayName }}</a-checkbox
                >
                <div
                  :class="[
                    'status',
                    formatOnlineState(item.onlineState, 'english'),
                  ]"
                >
                  <div class="icon"></div>
                  <div class="text">
                    {{ formatOnlineState(item.onlineState, 'chinese') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="no-data" v-else>
              <img
                class="icon"
                src="@/assets/img/address-book/default_contact.png"
                alt=""
              />
              <div>There are no contacts to view</div>
            </div>
          </a-spin>
        </div>
      </div>
    </div>
    <div class="selected-users">
      <div class="head">Member elected（{{ selectedUsersCount }}/10）</div>
      <div class="main list">
        <div
          class="item"
          v-for="item in selectedUsersList"
          :key="item.userId"
          @click="unselectThisUser(item)"
        >
          {{ item.displayName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import clonedeep from 'lodash.clonedeep'
import { USER_ONLINE_STATE } from '@/utils/constants'
import DepartmentsTree from './DepartmentsTree.vue'

const setIcon = data => {
  const ds = data.subDepartments
  Array.isArray(ds) && (data.subDepartments = ds.map(e => setIcon(e)))
  return { ...data, slots: { icon: 'organizationIcon' } }
}
// 二者为同一人
const sameUser = (a, b) => Number(a.userId) === Number(b.userId)
// 计算用户在线状态
const calculateState = user => {
  const { inMeeting, online, offline } = USER_ONLINE_STATE
  if (user?.onlineInfo?.length > 0) {
    // 同一个账号在多端登录情况下，只要有一个端是会中，则状态判定为会中
    const isInMeeting = user.onlineInfo.some(o => Number(o.customState) === 1)
    return isInMeeting ? inMeeting : online
  } else {
    return offline
  }
}

export default {
  name: 'AddressBook',
  components: { DepartmentsTree },
  props: {},
  data() {
    return {
      USER_ONLINE_STATE,
      departmentsReplaceFields: {
        children: 'subDepartments',
        title: 'depName',
        key: 'depId',
      },
      departmentsList: [],
      selectedDepartmentId: undefined,
      isFetchingUsersApi: false,
      usersList: [],
      needRequestUsers: true,
      publicAllUsers: [],
      selectedUsersList: [],
      onlineUsers: [],
    }
  },
  computed: {
    ...mapGetters(['hstWebEngine', 'localUser']),
    selectedUsersCount() {
      return this.selectedUsersList.length
    },
    isSelected() {
      const sul = this.selectedUsersList
      return item => sul.findIndex(e => sameUser(e, item)) !== -1
    },
    usersListSortedByState() {
      const { usersList, onlineUsers } = this
      const finalUsersList = usersList.map(e => {
        const onlineSelf = onlineUsers.find(o => sameUser(o, e))
        e.onlineState = calculateState(onlineSelf)
        return e
      })
      return finalUsersList.sort((a, b) => b.onlineState - a.onlineState)
    },
    isPublicPlatform() {
      return this.hstWebEngine.getPlatformType() === 'public'
    },
  },
  watch: {
    selectedUsersList(value) {
      this.$emit('selected-users-change', value)
    },
  },
  methods: {
    async init() {
      try {
        await this.queryDeptInfo()
        await this.queryDeptUser()
        await this.getOnlineUsers()
        this.onOnlineUserState()
      } catch (error) {}
    },
    destory() {
      this.departmentsList = []
      this.selectedDepartmentId = undefined
      this.usersList = []
      this.needRequestUsers = true
      this.publicAllUsers = []
      this.selectedUsersList = []
      this.onlineUsers = []
      this.notHaveAddressRight = undefined
      if (this.isOnOnlineUserState) {
        this.hstWebEngine.off('onOnlineUserState')
      }
    },
    // 获取在线用户
    async getOnlineUsers() {
      const res = await this.hstWebEngine.getOnlineUsers()
      this.onlineUsers = res || []
    },
    // 监听用户在线状态发生变化
    onOnlineUserState() {
      this.hstWebEngine.on('onOnlineUserState', res => {
        // this.getOnlineUsers() // 最简方案，浪费性能
        this.isOnOnlineUserState = true
        const cloneRes = clonedeep(res)
        delete cloneRes.userId
        const { userId, mutexType } = res
        let curUser = {}
        const onlineIndex = this.onlineUsers.findIndex(e => sameUser(e, res))
        if (onlineIndex > -1) {
          curUser = clonedeep(this.onlineUsers[onlineIndex])
          let typeIndex = curUser.onlineInfo.findIndex(
            o => o.mutexType === mutexType
          )
          if (typeIndex > -1) {
            curUser.onlineInfo[typeIndex] = cloneRes
            curUser.onlineInfo = curUser.onlineInfo.filter(
              e => e.state !== USER_ONLINE_STATE.offline
            )
          } else {
            curUser.onlineInfo = [...curUser.onlineInfo, cloneRes]
          }
          this.$set(this.onlineUsers, onlineIndex, curUser)
        } else {
          curUser = {
            userId,
            onlineInfo: [cloneRes],
          }
          this.onlineUsers = [...this.onlineUsers, curUser]
        }
        // 如果此人入会了或者退出登录了，需要取消勾选
        const { inMeeting, offline } = USER_ONLINE_STATE
        if ([inMeeting, offline].includes(calculateState(curUser))) {
          this.unselectThisUser(res)
        }
      })
    },
    // 获取组织列表
    async queryDeptInfo() {
      try {
        const res = await this.hstWebEngine.queryDeptInfo()
        await this.executeLoginInvaild(res)
        const { resCode, resMessage, msg, result } = res.data
        if (Number(resCode) === 1) {
          this.departmentsList = [setIcon(result)]
          this.selectedDepartmentId = this.departmentsList[0]?.depId
          return Promise.resolve()
        } else if (Number(resCode) === 20822) {
          this.departmentsList = []
          const errorMsg = 'You do not have permission to operate'
          this.$message.error(errorMsg)
          this.notHaveAddressRight = true
          return Promise.reject(errorMsg)
        } else {
          this.departmentsList = []
          const errorMsg = resMessage || msg || `Failed to query organization data：${resCode}`
          this.$message.error(errorMsg)
          return Promise.reject(errorMsg)
        }
      } catch (error) {
        console.log('exception in hstWebEngine.queryDeptInfo: ', error)
        return Promise.reject(error)
      }
    },
    // 获取组织下用户列表
    async queryDeptUser() {
      this.isFetchingUsersApi = true
      try {
        const depId = Number(this.selectedDepartmentId)
        if (this.isPublicPlatform && !this.needRequestUsers) {
          this.usersList = this.publicAllUsers.filter(
            e => Number(e.depId) === depId
          )
          this.isFetchingUsersApi = false
          return
        }
        const params = {
          depId,
          currentPage: 1,
          pageSize: 9999_9999, // 写死的虚假值，简化请求所有人员的参数
        }
        const res = await this.hstWebEngine.queryDeptUser(params)
        await this.executeLoginInvaild(res)
        const { resCode, resMessage, msg, result } = res.data
        if (Number(resCode) === 1) {
          // 需要在参会人列表中排除自己
          const { userId } = this.hstWebEngine.getUserLoginInfo()
          const { UserID } = this.localUser
          const curUserId = userId || UserID
          let usersList = result.items
          usersList = usersList.filter(
            e => Number(e.userId) !== Number(curUserId)
          )
          this.publicAllUsers = usersList
          this.needRequestUsers = false
          if (this.isPublicPlatform) {
            // 公有云接口不支持depId参数查询，所以需要自行过滤
            usersList = usersList.filter(e => Number(e.depId) === depId)
          }
          this.usersList = usersList
        } else {
          this.usersList = []
          this.$message.error(
            resMessage || msg || `Failed to query user data：${resCode}`
          )
        }
        this.isFetchingUsersApi = false
      } catch (error) {
        this.isFetchingUsersApi = false
        console.log('exception in hstWebEngine.queryDeptUser: ', error)
      }
    },
    // 处理登录失效
    executeLoginInvaild(res) {
      const { status } = res.data
      if ([401, 403].includes(status)) {
        this.errorHandler('Login is invalid, please login again')
        return Promise.reject(res.data)
      }
      return Promise.resolve()
    },
    onDepartmentSelect(id) {
      this.selectedDepartmentId = id
      this.queryDeptUser()
    },
    onRefreshUsers() {
      if (this.isFetchingUsersApi || this.notHaveAddressRight) return false
      this.needRequestUsers = true
      this.queryDeptUser()
    },
    onUserSelectChange(e, item) {
      const checked = e.target.checked
      if (checked) {
        if (this.selectedUsersCount >= 10) {
          this.$toast('Maximum number of members has been reached')
        } else {
          this.selectedUsersList = [...this.selectedUsersList, item]
        }
      } else {
        this.unselectThisUser(item)
      }
    },
    // 取消勾选某个用户
    unselectThisUser(item) {
      const sul = clonedeep(this.selectedUsersList)
      this.selectedUsersList = sul.filter(e => !sameUser(e, item))
    },
    errorHandler(content) {
      this.$error({
        content,
        onOk: () => {
          this.$router.replace({ path: '/login' })
        },
      })
    },
    // 根据语言选择格式化在线状态
    formatOnlineState(value, lang) {
      const state = value !== undefined ? value : USER_ONLINE_STATE.offline
      return USER_ONLINE_STATE[state][lang]
    },
  },
}
</script>

<style lang="less" scoped>
.address-book {
  display: flex;
  align-items: flex-start;
  .departments-users {
    width: 640px;
    margin-right: 10px;
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      .refresh-btn {
        width: 30px;
        height: 30px;
        cursor: pointer;
        background: url(~@/assets/img/meeting-list/btn_refresh.png);
        &:hover {
          background: url(~@/assets/img/meeting-list/btn_refresh_hot.png);
        }
      }
    }
    .main {
      margin-top: 5px;
      border: 1px solid #dee4e9;
      .title {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #dee4e9;
      }
      .content {
        @content-height: 420px;
        @departments-width: 270px;
        display: flex;
        height: @content-height;
        .departments {
          width: @departments-width;
          height: 100%;
          padding: 0 5px;
          border-right: 1px solid #dee4e9;
          overflow: auto;
        }
        .users-list-spin {
          width: calc(100% - @departments-width);
          overflow: hidden;
          .users {
            height: @content-height;
            background: #f9fbff;
            overflow-x: hidden;
            overflow-y: auto;
            .item {
              display: flex;
              align-items: center;
              height: 40px;
              padding: 0 25px;
              background: #fff;
              border-bottom: 1px solid #e9edf5;
              /deep/ .ant-checkbox-wrapper {
                flex: 1;
                padding-right: 15px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                .ant-checkbox-checked .ant-checkbox-inner {
                  background-color: #4a9cf7;
                  border-color: #4a9cf7;
                }
                .ant-checkbox-disabled + span {
                  color: unset;
                }
              }
              .status {
                width: 80px;
                display: flex;
                align-items: center;
                .icon {
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                  margin-right: 10px;
                }
                &.offline {
                  @color: #999ea3;
                  color: @color;
                  .icon {
                    background: @color;
                  }
                }
                &.inMeeting {
                  @color: #f56f6f;
                  color: @color;
                  .icon {
                    background: @color;
                  }
                }
                &.online {
                  @color: #37a700;
                  color: @color;
                  .icon {
                    background: @color;
                  }
                }
              }
            }
          }
          .no-data {
            height: @content-height;
            background: #f9fbff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            color: #999ea3;
            .icon {
              width: 180px;
              height: 180px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
  .selected-users {
    width: calc(100% - 650px);
    .head {
      height: 30px;
      display: flex;
      align-items: center;
      font-weight: bold;
    }
    .main {
      height: 460px;
      margin-top: 5px;
      border: 1px solid #dee4e9;
      background: #f9fbff;
      overflow: auto;
      .item {
        padding: 0 25px;
        background: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: 1px solid #e9edf5;
        line-height: 40px;
      }
    }
  }
}
</style>
