<template>
  <a-modal
    class="address-book-modal"
    title="address book"
    :width="960"
    destroy-on-close
    :ok-button-props="{ props: okButtonProps }"
    v-model="visible"
    @ok="handleOk"
  >
    <div class="address-book-wrapper">
      <address-book
        ref="addressBookRef"
        @selected-users-change="onSelectedUsersChange"
      />
    </div>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import AddressBook from './index.vue'

export default {
  name: 'AddressBookModal',
  components: { AddressBook },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedUsers: [],
      okButtonProps: {
        disabled: true,
        loading: false,
      },
    }
  },
  computed: {
    ...mapGetters(['hstWebEngine']),
    visible: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    visible(value) {
      this.$nextTick(() => {
        const { init, destory } = this.$refs.addressBookRef
        value ? init() : destory()
      })
    },
  },
  methods: {
    onSelectedUsersChange(users) {
      this.selectedUsers = users
      this.okButtonProps.disabled = users.length === 0
    },
    handleOk() {
      this.$emit('ok', this.selectedUsers)
    },
  },
}
</script>

<style lang="less" scoped>
.address-book-modal {
  .address-book-wrapper {
    padding: 20px 25px;
  }
  /deep/.ant-modal-footer {
    border-top: none;
    padding: 0 25px 20px;
  }
}
</style>
